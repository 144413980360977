






// @ts-ignore
import { AggregateTransactionTs } from './AggregateTransactionTs';

export default class InputLock extends AggregateTransactionTs {}
